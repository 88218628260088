import _ from "lodash";

export default function (assetField, propertyField, loanSeniority) {
  const propertyName = _.get(propertyField, "fieldContent.name");
  let propertyLabel = "";

  if (propertyName) {
    propertyLabel = `${propertyName}: `;
  } else if (propertyField?.joiningContentType === "Property") {
    propertyLabel = `${propertyField.joiningContentType} ${propertyField.joiningContentId}: `;
  }

  const name = assetField?.fieldContent?.name;
  const assetType =
    assetField?.fieldContent?.type ||
    assetField?.fieldContentSubType ||
    assetField?.fieldContentType;
  const unnamed = _.lowerCase(name) === _.lowerCase(assetType);
  let label = null;

  if (!unnamed) {
    label = `${name} (${_.startCase(assetType)})`;
  } else {
    label = `${_.startCase(assetType)} ${assetField?.fieldContentId}`;
  }

  if (assetField?.decoratingContentType === "Loan") {
    const seniority = loanSeniority ? `${loanSeniority} ` : "";
    return `${propertyLabel}${seniority}${assetField?.decoratingContentType} ${assetField?.decoratingContentId} secured by ${label}`;
  } else {
    return `${propertyLabel}${label}`;
  }
}
